import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center my-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_QuotationSummaryCard = _resolveComponent("QuotationSummaryCard")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "mb-24",
    narrow: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, { level: 2 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Thank you for your order!")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "We appreciate your trust and loyalty. We hope you enjoy your shopping experience with us and come back soon."
          )), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "You have made a great choice and we are sure you will love the output."
          )), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_QuotationSummaryCard, {
        quotation: _ctx.quotation,
        loading: _ctx.quotationLoading,
        class: "mb-10",
        "promo-code-disabled": ""
      }, null, 8, ["quotation", "loading"])
    ]),
    _: 1
  }))
}