
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import QuotationSummaryCard from "@/shared/components/QuotationSummaryCard.vue";
import { useQuotation } from "@/shared/composables/useQuotation";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { hasResponseErrorCode } from "@/shared/utils/graphql/responseParser";
import { apiErrorCodes } from "@/shared/utils/constants";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { redirectToQuote } = useRedirectHelper();

    const quotationUuid = ref(String(route.params.uuid ?? ""));

    const { quotationParsedResponse, quotation, quotationLoading } =
      useQuotation({ quotationUuid });

    watch(quotationParsedResponse, (response) => {
      if (hasResponseErrorCode(response, apiErrorCodes.INVALID_INPUT)) {
        redirectToQuote();
      }
    });

    return {
      t,
      quotation,
      quotationLoading,
    };
  },
  components: {
    PageLayout,
    QuotationSummaryCard,
  },
});
